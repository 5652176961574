import React from "react";
import "./index.css";
//import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const BookCard = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/viewfile/" + props.ID);
  };

  return (
    <div className="BookContainer">
      <div className="BookPhoto">
        <img
          src={"../" + props.PhotoURL}
          alt="PhotoURL"
          className="BookImage"
          style={{ width: "200px", height: "240px", cursor: "pointer" }}
          onClick={() => handleClick(props.File)}
        />
        <img
          src="../books/bs.png"
          alt="Best"
          className="BestImage"
          style={{ width: "40px", height: "40px" }}
        />
      </div>
      <div className="BookIntro">
        <div className="BookTitle" onClick={() => handleClick(props.File)}>
          {props.Title}
        </div>
        <div className="BookAuthor">{props.Author}</div>
        <div className="BookScore">{props.Score}</div>
        <div className="BookDesc">{props.Desc}</div>
        <div className="BookWords">
          Compressed Original {props.Origin} to <span>{props.Compress} </span>
          Words!
        </div>
      </div>
      <div className="SepLine"></div>
    </div>
  );
};
export default BookCard;

import BookCard from "../BookCard/index.js";
import "./index.css";
import { useState, useEffect } from "react";

// 合集列表
function CollectionList(props) {
  var cachedData = localStorage.getItem("myBooklist" + props.ID);
  if (!cachedData) {
    console.log("No cache data");
  } else {
    cachedData = JSON.parse(cachedData);
  }
  const [data, setData] = useState(cachedData);

  useEffect(() => {
    // 请求网络数据
    fetch("https://insightreader.ai/api/docs/collections/?id=" + props.ID)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData.data);
        localStorage.setItem(
          "myCollection" + props.ID,
          JSON.stringify(jsonData.data)
        );
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  }, [props.ID]);

  return (
    <div>
      <div className="CollectionContainer">
        {data ? (
          data.map((item) => (
            <div key={item.ID} className="BookItem">
              <BookCard
                ID={item.ID}
                PhotoURL={item.Photo}
                Title={item.Title}
                Author={item.Author}
                Score={item.Score}
                Desc={item.Desc}
                File={item.File}
                Origin={item.Origin}
                Compress={item.Compress}
              />
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

export default CollectionList;

import React from "react";
import ReactGA from "react-ga";
import PDFViewer from "../../components/PDFViewer";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const PageFile = () => {
  const navigate = useNavigate();
  const { fileId } = useParams();

  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <div className="CenterList">
        <button onClick={() => navigate(-1)}> Back </button>
        <PDFViewer pdfUrl={"../pdfs/" + fileId + ".pdf"} />
      </div>
    </div>
  );
};

export default PageFile;

import React from "react";
import ReactGA from "react-ga";
//import PDFViewer from "../components/PDFViewer";
import CollectionList from "../../components/CollectionList";
import { CollectionArray } from "../../components/Tab/data.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./index.css";

const PageList = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const navigate = useNavigate();
  const { collectionId } = useParams();

  //console.log(collectionId);
  //console.log(CollectionArray);
  var title = CollectionArray.find((item) => item.ID === collectionId).Title;
  document.title = title;

  return (
    <div>
      <div className="CenterList">
        <h1>{title}</h1>
        <CollectionList ID={collectionId} />
        <button
          style={{
            Width: "100px",
            Height: "50px",
            FontSize: "20px",
            marginBottom: "20px",
          }}
          onClick={() => navigate(-1)}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PageList;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import PageList from "./pages/PageList";
import PageFile from "./pages/PageFile";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/viewlist/:collectionId" element={<PageList />} />
        <Route path="/viewfile/:fileId" element={<PageFile />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

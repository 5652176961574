import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const RecCard = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/viewlist/" + props.ID);
  };

  return (
    <div>
      <div className="RecContainer">
        <div>
          <img
            className="RecPhoto"
            src={props.PhotoURL}
            alt="description"
            onClick={handleClick}
          />
          <div className="RecTitle">{props.Title}</div>
        </div>
      </div>
    </div>
  );
};
export default RecCard;
